body.container-lg {
  .container {
    max-width: 960px;
  }
  .mc.w-940 {
    margin-left: 0;
  }
}

#opener {
  body[data-offset="0"] & {

    &:before {
      top: 20px;
    }
    &:after {
      bottom: 19px;
      transform: rotate( 90deg );
    }

    html.nav-open & {
      &:before {
        transform: rotate( -45deg ) translate( 0, 0 );
      }
      &:after {
        transform: rotate( 45deg ) translate( 0, 0 );
      }
    }

  }
}

#quick-access {
  position: absolute;
  top: $grid-gutter-width;
  right: $grid-gutter-width + 40px;
  display: block;

  font-family: $headings-font-family;
  font-weight: 400;
  transform: translate( 0, -12px );
  line-height: 40px;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    flex: 0 0 auto;
    margin: 0 math.div( $grid-gutter-width, 2 );

    &:after {
      content: '';
      margin-left: 15px;
      margin-right: -15px;
      position: relative;
      display: inline-block;
      opacity: .44;
      width: 14px;
      height: 1.4px;
      @include themed() {
        background: t( 'body-color' );
      }
      margin-bottom: 4px;
    }
  }
  a {
    @include themed() {
      color: t( 'body-color' );
    }
    text-decoration: none;
  }
}

h2, .h2 {
  font-size: $h2-font-size-lg;
  font-size: calc( .033 * 100vw );

  .h2sup {
    font-size: $h3-font-size-lg;
    font-size: calc( .024 * 100vw );
  }
}

h1 {
  max-width: $max-width-title;
}
.entry-content {
  p, ul, ol {
    max-width: $max-width-text;
  }
}
.entry-content {
  nav {
    ul, ol {
      max-width: none;
    }
  }
}

// Content

.entry-header {
  .breadcrumb,
  .entry-title,
  .intro,
  .header-meta {
    padding-left: $space-8 * 2;
  }
}

/**
 * For calculations
 */
/**
 * Colors
 */
/**
 * Grid
 */
/**
 * Types
 */
/**
 * Spacing
 */
/**
 * Timing
 */
body.container-lg .container {
  max-width: 960px;
}
body.container-lg .mc.w-940 {
  margin-left: 0;
}

body[data-offset="0"] #opener:before {
  top: 20px;
}
body[data-offset="0"] #opener:after {
  bottom: 19px;
  transform: rotate(90deg);
}
html.nav-open body[data-offset="0"] #opener:before {
  transform: rotate(-45deg) translate(0, 0);
}
html.nav-open body[data-offset="0"] #opener:after {
  transform: rotate(45deg) translate(0, 0);
}

#quick-access {
  position: absolute;
  top: 30px;
  right: 70px;
  display: block;
  font-family: "Tisa Pro", serif;
  font-weight: 400;
  transform: translate(0, -12px);
  line-height: 40px;
}
#quick-access ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
#quick-access li {
  flex: 0 0 auto;
  margin: 0 15px;
}
#quick-access li:after {
  content: "";
  margin-left: 15px;
  margin-right: -15px;
  position: relative;
  display: inline-block;
  opacity: 0.44;
  width: 14px;
  height: 1.4px;
  background: #212529;
  margin-bottom: 4px;
}
@media (prefers-color-scheme: dark) {
  #quick-access li:after {
    background: #fff;
  }
}
#quick-access a {
  color: #212529;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  #quick-access a {
    color: #fff;
  }
}

h2, .h2 {
  font-size: 2.1875rem;
  font-size: 3.3vw;
}
h2 .h2sup, .h2 .h2sup {
  font-size: 1.65rem;
  font-size: 2.4vw;
}

h1 {
  max-width: 540px;
}

.entry-content p, .entry-content ul, .entry-content ol {
  max-width: 540px;
}

.entry-content nav ul, .entry-content nav ol {
  max-width: none;
}

.entry-header .breadcrumb,
.entry-header .entry-title,
.entry-header .intro,
.entry-header .header-meta {
  padding-left: 128px;
}

@media (min-width: 1200px) {
  body.container-lg .container {
    max-width: 960px;
  }
}